import React from "react"
import { graphql,Link } from 'gatsby'

import Layout from "../../components/layout"
import Next from "../../components/next"

const Page = ({data}) => (
  <Layout title="Smartix" keywords={[`rail api`, `train ticket`, `api`]} submenu={data.allRailapisubmenuJson.edges}>
    <content>
      <h1>Journey Planner</h1>

      <blockquote>
        <span>Interested in having your journey planner integrated?</span>
        <cite><Link to="/contact-us/">Drop us a line</Link></cite>
      </blockquote>

      <p><Link to="/smartix/" className="printLink">smartix</Link> does not include a journey planner. We decided from the outset that 
      our customers should always be free to choose any journey planner. There are several long standing journey planners and a few
      new entrants which support split ticketing, each with its own strengths and weaknesses.</p>

      <p>Our unique <Link to="/smartix/architecture/">architecture</Link> provides a stronger bond with your chosen journey planner.
      This means that you have to manage far less data which translates to faster development, faster testing and more reliable operation.</p>

      <Next submenu={data.allRailapisubmenuJson.edges}/>



    </content>
  </Layout>
)

export const query = graphql`
  query {
    allRailapisubmenuJson {
      edges {
        node {
          title
          path
        }
      }
    }
  } 
`

export default Page